
import { Component, Vue } from 'vue-property-decorator'
import { apiSystemSystemEnv } from '@/api/setting'

@Component
export default class Environment extends Vue {
  environmentData: Object = {};

  /** S Methods **/
  async getSystemEnvFunc (): Promise<void> {
    const res = await apiSystemSystemEnv()
    this.environmentData = res
  }

  created () {
    this.getSystemEnvFunc()
  }
}
